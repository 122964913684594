//Librerias
import { useState } from 'react';
import React from 'react';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate,Navigate } from 'react-router-dom';
//CSS
import '../Style/Login.css'

function Login(){
    const navigate = useNavigate();
    const auth = getAuth();
    const [Email,setEmail] = useState('');
    const [Password,setPassword] =useState('');
    const SubmitHand=e=>{
        e.preventDefault();
        signInWithEmailAndPassword(auth, Email, Password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                const tokenR= user.uid;
                sessionStorage.setItem('token',tokenR);
                navigate('/Administracion');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode + errorMessage);
            });
        
     };
     let token = sessionStorage.getItem('token');
    return(
        <>
            { token && <Navigate to='/Administracion' replace={true} />}
            <h2> Inicia Sesion</h2>
            <form className="d-flex align-items-center flex-column formm" onSubmit={SubmitHand}>
                <label className='form-label mb-0 mx-2'>
                    <input className='form-control' type="email" name="email" onChange={ (ev)=> setEmail(ev.target.value)} placeholder="email"/>
                    <input className='form-control' type="password" name="email" onChange={(ev) => setPassword(ev.target.value)} placeholder="Contraseña"/>
                </label>
                    <button className='btn btn-primary boton' type="submit">Iniciar Sesion</button>
            </form>
            
        </>
    )
}

export default Login;