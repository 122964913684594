import React, {useRef} from 'react'
import { toast } from "react-toastify";
import emailjs from '@emailjs/browser';

//CSS
import "../Style/App.css"
import "../Style/cards.css"
import "../Style/Contacto.css";
import 'react-toastify/dist/ReactToastify.css';


function Inicio(){
    const  formm = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ev8eojs', 'template_owirg3h', formm.current, 'IdwLFRu45wxDTH8O9')
          .then((result) => {
              console.log(result.text);
              toast.success("Enviado Correctamente");
          }, (error) => {
              console.log(error.text);
              toast.error("No se logro Enviar Correctamente");
              
          });
      };

    return(
        <>
            <div >
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    </div>
                    <div className="carousel-inner carousell">
                        <div className="carousel-item active">
                            <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FInicio%2FPrincipal2.jpg?alt=media&token=d4786fb6-3c3e-4c5b-9956-055c0cffeb6d" className="d-block imgg" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742922/Pages/Foto1_p59yct.jpg" className="d-block imgg" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742922/Pages/Foto2_zawexy.jpg" className="d-block imgg" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742922/Pages/Foto3_ukoivt.jpg" className="d-block imgg" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742923/Pages/Foto4_qhyrir.jpg" className="d-block imgg" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742923/Pages/Foto5_z4gwde.jpg" className="d-block imgg" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FInicio%2FFoto6.jpeg?alt=media&token=2ceaa667-48a4-493d-b62a-3121ecb7208c" className="d-block imgg" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div>
                <h2 className="SubTitle"> POR QUE TRABAJAR CON NOSOTROS</h2>
                <div className="minWrapper">
                        <div className="card one">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742924/Pages/Tar1_y2vlig.png" alt=""/>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>Contamos con más de 20 años de experiencia</a>
                        </div>
                        <div className="card two">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742923/Pages/Tar2_jzkhvp.png" alt=""/>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>Tenemos abogados especializados en las distintas áreas de derecho</a>
                        </div>
                        <div className="card three">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742924/Pages/Tar3_a8np2z.png" alt=""/>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>Equipo interdisciplinario de trabajo</a>
                        </div>
                        <div className="card four">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742924/Pages/Tar4_fylutr.png" alt=""/>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>Cobertura a nivel nacional</a>
                        </div>
                        <div className="card five">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742925/Pages/Tar5_nmdrcu.png" alt=""/>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>Somos una empresa
                                comprometida con el cliente,
                                te aseguramos puntualidad e
                                información.</a>
                        </div>
                        <div className="card six">
                            <img src="https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742925/Pages/Tar6_tuxowc.png" alt=""/>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>Facilidad en la comunicación
                                gracias al uso de las TIC.</a>
                        </div>
                    
                </div>
            </div>


            <div>
                <h2 className="SubTitle">Contactenos</h2>
                
                <div className="contenedorMapa">
                    <div className="CuadroTextMap">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>Comunícate pronto y permite que
                        nuestros profesionales calificados se
                        encarguen de tu consulta, la
                        resolución de tu conflicto o trámite
                        legal.
                        Para nosotros es un placer atenderle,
                        por favor háganos saber su consulta
                        por el siguiente medio, o llamando nuestras lineas de atencion.</a>
                        <div  className="Formularioo">
                            <form ref={formm} onSubmit={sendEmail}>
                                        <label htmlFor="name" >Nombre:</label>
                                        <input type="text" id="name" name="user_name" placeholder="Nombre y Apellido" required/>
                                        <label htmlFor="mail">Correo electrónico:</label>
                                        <input type="email" id="mail" name="user_mail" placeholder="comercial@gruposigmajudicial.com" required/>
                                        <label htmlFor="phone">Numero:</label>
                                        <input type="phone" id="phone" name="user_phone" placeholder="Numero de Contacto." required/>
                                        <label htmlFor="msg">Consulta:</label>
                                        <textarea id="msg" name="user_message" ></textarea>
                                        <input className="buttom" type="submit" value="Enviar"/>
                            </form>
                        </div>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <iframe title="Ubicacion en el mapa" className="mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1823.4360804713122!2d-74.07535173106109!3d4.604414901303987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa40cbc67f3b0be87!2sGRUPO%20SIGMA%20JUDICIAL!5e0!3m2!1ses!2sco!4v1652281207073!5m2!1ses!2sco" ></iframe>
                </div>
            </div>
        </>
    )
}

export default Inicio;