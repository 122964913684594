import "../Style/carousell.css"
import React from 'react';


function CompromisoSocial(){
    return(
        <>
            
        <div className="Compromisoo">
            <h2>Compromiso Social</h2>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Nuestra empresa se ha destacado a lo largo de estos años por su compromiso social y
                humanitario, es por ello que ha creado programas en beneficio de las poblaciones vulnerables
                y de escasos recursos, entre ellos: recolectas, celebraciones en fechas especiales, apoyo
                económico a fundaciones, entrega de ayudas como útiles escolares, vestuario, elementos de
                aseo, etc., y orientación jurídica.</a>
        </div>

        <div >
            <div id="carouselExampleIndicators" className="carousel slide carousellCompro" data-bs-ride="true">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" aria-label="Slide 12"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="12" aria-label="Slide 13"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso1.jpeg?alt=media&token=c32903f0-b567-4e73-9334-c6cd687c01d5" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso2.jpeg?alt=media&token=808e0228-7c5d-451a-a83a-077c503cd86e" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso3.jpeg?alt=media&token=1ae898b5-c5f1-4042-8acc-8247d7bafe26" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso4.jpeg?alt=media&token=7ce8e7f1-3ae1-44c8-8ef0-24a68547c996" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso5.jpeg?alt=media&token=0a874413-ecb3-4974-bd7e-c180182a5009" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso6.jpeg?alt=media&token=ebce2bd6-85e0-43a5-a3b0-ceb0deec5917" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso7.jpeg?alt=media&token=8973a85c-190f-4c39-aa51-1356d6615ec4" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso8.jpeg?alt=media&token=d1701e84-c2d1-4747-b304-de3b1a6939d6" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso9.jpeg?alt=media&token=63ed48bb-892c-45c4-8474-9898fc357467" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso10.jpeg?alt=media&token=f0ab7aac-f12e-49fd-bf7a-b40f628e3309" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso11.jpeg?alt=media&token=b591c17d-fd6b-43fb-8efd-22fe70639c0d" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso12.jpeg?alt=media&token=ae8e83ce-4a85-475b-9410-05b558babba1" className="d-block imggCrompo" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sigmaofi.appspot.com/o/Imagenes%2FCompromisoSocial%2FCompromiso13.jpeg?alt=media&token=1c59fb3d-40d5-456e-8af0-e448f27c3e7c" className="d-block imggCrompo" alt="..."/>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        </>
    )
}

export default CompromisoSocial;