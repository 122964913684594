import React from "react";

import "../Style/carousell.css"
import "../Style/Inmo.css"


function InmobiliariaM(vivienda){
    const prueba = vivienda.e.Name.split(" ");
    return(
        <>
        <div className="contenedorInmo" key={vivienda.e.Name} >
            <div id={"carouselExampleIndicators"+prueba[0]} className="carousel slide carousellInmo" data-bs-ride="true">
                <div className="carousel-indicators" >
                    <button type="button"  data-bs-target={"#carouselExampleIndicators"+prueba[0]} data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    {   

                            vivienda.e.urlDescargas.slice(1,vivienda.e.urlDescargas.length).map((image,idImage)=>{
                                
                                return(
                                    <button type="button" data-bs-target={"#carouselExampleIndicators"+prueba[0]} key={idImage} data-bs-slide-to={idImage+1} aria-label={`Slide ${idImage+1}`}></button>
                                )
                            }) 
    
                    }
                </div>
                <div  className="carousel-inner">
                
                    {   

                            vivienda.e.urlDescargas.slice(0,1).map((image,idImage)=>{
                            
                                return(
                                    <div className="carousel-item active" key={idImage}>
                                        <img key={idImage} src={image} className="imggInmo" alt="..."/>
                                    </div>
                                )
                            }) 

                    
                    }
                    {   

                            vivienda.e.urlDescargas.slice(1,vivienda.e.urlDescargas.length).map((image,idImage)=>{
                               
                                return(
                                    <div className="carousel-item" key={idImage}>
                                        <img key={idImage} src={image} className="imggInmo" alt="..."/>
                                    </div>
                                )
                            }) 

                    
                    }
                
                </div>
                <button  className="carousel-control-prev" type="button" data-bs-target={"#carouselExampleIndicators"+prueba[0]} data-bs-slide="prev">
                    <span  className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span  className="visually-hidden">Previous</span>
                </button>
                <button  className="carousel-control-next" type="button" data-bs-target={"#carouselExampleIndicators"+prueba[0]} data-bs-slide="next">
                    <span  className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span  className="visually-hidden">Next</span>
                </button>
            </div>
            <div>
                {

                            <div  className="TextImno">
                                <h2  className="tituloInmo">{vivienda.e.Name}</h2>
                                {vivienda.e.Arriendo && !vivienda.e.Venta ? <p className="EstadoInmo">En Arriendo</p> : ""}
                                {vivienda.e.Venta && !vivienda.e.Arriendo ? <p className="EstadoInmo">En venta</p> : ""}
                                {vivienda.e.Venta && vivienda.e.Arriendo ? <p className="EstadoInmo">En venta o Arriendo</p> : ""}
                                
                                <p  className="DescripInmo">{vivienda.e.Descripcion}</p>
                                <p  className="precioInmo"> Precio Del inmueble: {vivienda.e.Precio}</p>
                            </div>

                }
                
            </div>
        </div>
        </>
    )
}

export default InmobiliariaM;