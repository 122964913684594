import React,{useState, useEffect} from "react";
import { toast } from "react-toastify";
import { getDatabase,ref,set,onValue,remove} from "firebase/database";
import {uploadBytes,getDownloadURL, ref as refStorage,deleteObject} from "firebase/storage";
import {Navigate } from 'react-router-dom';


//CSS
import "../Style/Admin.css"
import 'react-toastify/dist/ReactToastify.css';
import { storage } from "../firebase/firebaseConfig";

const initialState = {
    Name: "",
    Venta: false,
    Arriendo:false,
    Descripcion: "",
    Precio:""
}


function writeData(Name, Venta, Arriendo,Descripcion,Precio,urlDescargas,nameDescargas) {
    const db = getDatabase();
    set(ref(db, 'Vivienda/'+Name), {
      Name: Name,
      Venta: Venta,
      Arriendo : Arriendo,
      Descripcion: Descripcion,
      Precio: Precio,
      urlDescargas:urlDescargas,
      nameDescargas:nameDescargas
    });
  }

function Administracion(){
    const [state,setState]=useState(initialState);
    const [data,setData]=useState([]);
    const [viviendas,setViviendas]=useState([]);
    
    useEffect(()=>{
        const db = getDatabase();
        const viviendaRef = ref(db, 'Vivienda');
        onValue(viviendaRef,(snapshot) => {
            const datosV = snapshot.val();
            console.log(datosV);
            setViviendas(Object.values(datosV));
        });
    },[setViviendas]);

    const {Name,Venta,Arriendo,Descripcion,Precio}=state;
    
        

    const handleInputSubmit = e =>{
        const {name,value} = e.target;
        setState({ ...state, [name]:value});
    };
    const final = [];
    const fileHandler = e =>{
        e.preventDefault([]);
        
        const archivoLocal = e.target.files;
        for(var i=0; i<archivoLocal.length;i++){
            final.push(archivoLocal[i]);
        }
        setData(final);
        
    }

    const secondSubmitHandler = async e =>{
        e.preventDefault();
        const db = getDatabase();
        const btn = e.currentTarget;
        const parent = btn.parentElement;
        const Name = parent.querySelector('button').getAttribute('id'); 

        viviendas.map(async e =>{
            if (e.Name === Name) {
                console.log("shi");
               e.nameDescargas.forEach(async namee => {
                    const archivoRef = refStorage(storage,"documentos/"+Name+"/"+namee);
                    await deleteObject(archivoRef).then(()=>{
                        toast.success("Archivos Eliminados Correctamente");
                    }).catch((err)=>{
                        toast.error("No se logro Eliminar Correctamente los archivos");
                        console.log(err);
                    })
               })
               
            }
        })
        
        await remove(ref(db, 'Vivienda/'+Name))
            .then(res=>{
            toast.success("Eliminado Correctamente");
            }).catch(e=>{
                toast.error("No se logro Eliminar Correctamente");
            })
            
        

    }

    const submitHandler = async  e =>{
        e.preventDefault();
        
        if(!Name || !Descripcion || !Precio ){
            toast.error("Porfavor Ingresar Valores");
        }else{
            const urlDescargas=[];
            const nameDescargas=[];
            for(var i=0;i<data.length;i++){
                //Crear referencia
                const archivoRef = refStorage(storage,`documentos/${Name}/${data[i].name}`);
                //Cargar Archivo
                await uploadBytes(archivoRef,data[i]);
                //Obtener Url
                urlDescargas.push(await getDownloadURL(archivoRef));
                console.log("logrado");
                nameDescargas.push(data[i].name);
            }
            writeData(Name,Venta,Arriendo,Descripcion,Precio,urlDescargas,nameDescargas)
            toast.success("Agregado Correctamente");
        }
    }
    
    let token = sessionStorage.getItem('token');

    return(
        <>
            { !token && <Navigate to='/Login' replace={true} />}
            <div className="d-flex">
                <form className="Formulariooo ms-3 me-4 mt-5" id="Formm"  onSubmit={submitHandler}>
                    <label htmlFor="name">Titulo:</label>
                    <input type="text" id="name" name="Name" placeholder="Titulo de vivienda" onChange={handleInputSubmit} value={Name} />
                    <input className="form-control" type="file" id="formFileMultiple" multiple onChange={fileHandler}/>            
                    <div>
                        <div className="form-check form-check-inline">
                            <input name="Venta" className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={handleInputSubmit}  value={Venta ? false : true}/>
                            <label  className="form-check-label" htmlFor="inlineCheckbox1">Venta</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="Arriendo" className="form-check-input" type="checkbox" id="inlineCheckbox2" onChange={handleInputSubmit}  value={Arriendo ? false : true}/>
                            <label className="form-check-label" htmlFor="inlineCheckbox2">Arriendo</label>
                        </div>
                    </div>
                    <label htmlFor="msg">Descripcion:</label>
                    <textarea id="msg" name="Descripcion" onChange={handleInputSubmit} value={Descripcion}></textarea>
                    <label htmlFor="name">Precio:</label>
                    <input type="text" id="name" name="Precio" placeholder="Precio" onChange={handleInputSubmit} value={Precio}/>
                    <input className="buttom" type="submit" value="Enviar"/>
                </form>
                <div className="w-50">
                    <div className="d-flex align-items-center justify-content-evenly">
                        <h2 className="">Lista De Propiedades</h2>
                    </div>
                    <div className="accordion" id="accordionExample">

                        {
                            viviendas.map((vivienda,idx)=>{
                                return(
                                    <div className="accordion-item" key={idx}>
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+idx} aria-expanded="true" aria-controls="collapseOne">
                                            {vivienda.Name}
                                        </button>
                                        </h2>
                                        <div id={"collapse"+idx} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div>
                                                <h5>Descripcion:</h5>
                                                {vivienda.Descripcion}</div>
                                            <hr />
                                            <div> <h5>La vivienda esta:</h5>
                                            {vivienda.Venta && <p className="d-block">En Venta</p>}
                                            
                                            {vivienda.Arriendo && <p>En Arriendo</p>}
                                            </div>
                                            
                                           <hr />
                                           Precio:<strong> {vivienda.Precio}</strong>
                                           <hr />
                                            <div> 
                                                <h5>Imagenes:</h5>
                                                {vivienda.urlDescargas.map((image,idImage)=>{
                                                    return(
                                                        <img className="imagesAdmin" key={idImage} id={idImage} src={image} alt='p'></img>
                                                    )
                                                })
                                                }
                                            </div>
                                            <div>
                                                <form onSubmit={secondSubmitHandler}>
                                                    <button className="btn btn-secondary " type="submit" id={vivienda.Name}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                                        </svg>
                                                    </button>              
                                                </form>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                )
                            })   
                        }
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default Administracion;