// Librerias
import React,{useEffect,useState} from 'react';
import {Link,useLocation} from 'react-router-dom';
//CSS
import "../Style/header.css"
function Header(){
    const [activeTab,setActiveTab] = useState('Inicio');
    const location = useLocation();

    useEffect(()=>{
        if(location.pathname === "/"){
            setActiveTab("Inicio");
        }else if(location.pathname=== "/Service"){
            setActiveTab("Area de Servicio");
        }else if(location.pathname=== "/Equipo"){
            setActiveTab("Equipo");
        }else if(location.pathname=== "/CompromisoSocial"){
            setActiveTab("CompromisoSocial");
        }
        // else if(location.pathname=== "/Contacto"){
        //     setActiveTab("Contacto");
        // }
    },[location])
    return(
        // eslint-disable-next-line
        <header>
             <div>
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid prue">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="navbar-brand logo">
                            <img src={"https://res.cloudinary.com/dpk1fnwji/image/upload/v1660742923/Pages/Logo1_yvay8e.svg"} alt="" className="d-inline-block align-text-top img-fluid"/>
                            <p className="fs-2">Grupo Sigma Judicial</p>
                        </a>
                        <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse itemss" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeTab ==="Inicio"? "active" : ""}`} 
                                    onClick={()=> setActiveTab("Inicio")} to='/' >Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeTab ==="Area de Servicio"? "active" : ""}`} 
                                    onClick={()=> setActiveTab("Area de Servicio")} to='/Service'>Area de Servicios</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeTab ==="Equipo"? "active" : ""}`} 
                                    onClick={()=> setActiveTab("Equipo")} to='/Equipo'>Nuesto Equipo</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeTab ==="CompromisoSocial"? "active" : ""}`} 
                                    onClick={()=> setActiveTab("CompromisoSocial")} to='/CompromisoSocial'>Compromiso Social</Link>        
                                    
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeTab ==="Inmobiliaria"? "active" : ""}`} 
                                    onClick={()=> setActiveTab("Inmobiliaria")} to='/Inmobiliaria'>Inmobiliaria</Link>        
                                </li>
                                {/* <li className="nav-item">
                                    <Link className={`nav-link ${activeTab ==="Contacto"? "active" : ""}`} 
                                    onClick={()=> setActiveTab("Contacto")} to='/Contacto'>Contactenos</Link>        
                                </li> */}
                                
                            </ul>
                        </div>
                    </div>
                </nav>
        </div>
        </header>
    )    
}

export default Header;