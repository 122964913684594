//librerias
import {Routes,Route} from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import React from 'react';

//Componentes
import Header from './Components/Header';
import Footer from './Components/Footer';
import Inicio from './Components/Inicio';
import Service from './Components/Service';
import Equipo from './Components/Equipo';
import CompromisoSocial from './Components/CompromisoSocial';
import Administracion from './Components/Administracion';
import Inmo from './Components/Inmo'
//CSS
import "./Style/App.css";
import Login from './Components/Login';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <div className='Wrapper'>
        <ToastContainer />
        <Header/>
          <Routes>
            <Route exact path='/' element={<Inicio/>}/>
            <Route path='/Service' element={<Service/>}/>
            <Route path='/Inmobiliaria' element={<Inmo/>}/>
            <Route path='/Equipo' element={<Equipo/>}/>
            <Route path='/CompromisoSocial' element={<CompromisoSocial/>}/>
            <Route path='/Administracion' element={<Administracion/>}/> 
            <Route path='/Login' element={<Login/>}/>
          </Routes>
        <Footer/>
        
      </div>
    </>
  );
}

export default App;
