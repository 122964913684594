import React,{useState, useEffect} from "react";
import { getDatabase,ref,onValue} from "firebase/database";


import InmobiliariaM from './InmobiliariaM';

function Inmo() {
    const [viviendas,setViviendas]=useState([]);
   
    useEffect(()=>{
        const db = getDatabase();
        const viviendaRef = ref(db, 'Vivienda');
        onValue(viviendaRef,(snapshot) => {
            const datosV = snapshot.val();
            setViviendas(Object.values(datosV));
        });
    },[setViviendas]);

    return(
        <>
        <h1>Inmobiliaria</h1>
        {
            viviendas.map((e,id)=>{
                return(
                    <InmobiliariaM 
                    key={id}
                    e={e}
                    />
                )
            })
        }
        </>
    )
    
}

export default Inmo